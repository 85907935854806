* {
  font-family: "Poppins", "sans-serif";
}

body {
  @apply bg-stone-50;
}

// scroll
* {
  ::-webkit-scrollbar {
    width: 20px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
  }
}

.table th:first-child {
  position: initial !important;
  left: 0px;
  z-index: 11;
}

.sticky-column {
  position: sticky;
  right: 0;
  z-index: 1;
}

// .swiper-container {
//   width: 100%;
//   height: 100%;
// }

// .swiper-slide {
//   width: auto;
// }

app-drawer {
  height: 100%;
  .drawer {
    display: grid;
    width: 100%;
    height: 100%;
    overflow: auto !important;
  }
}

.input,
.select {
  @apply h-12 w-full rounded-[10px] bg-zinc-100 px-5 text-base;
}

.textarea {
  @apply w-full rounded-[10px] bg-zinc-100 px-5 text-base;
}

.field-error {
  @apply ml-1 mt-1 block text-start text-xs text-red-600;
}

app-login {
  .title {
    @apply text-[32px] font-semibold text-primary;
  }

  .sub-title {
    @apply mb-10 mt-1 text-lg font-medium text-neutral-400 md:text-xl;
  }
}

.fact-stat {
  background: linear-gradient(to right, #ffa9f6, #afdef7);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

app-portfolio {
  dialog {
    div {
      &::-webkit-scrollbar {
        display: "none";
        width: 0;
      }

      -ms-oveflow-style: "none"; /* Internet Explorer and Edge */
      scrollbar-width: "none"; /* Firefox */
    }
  }
}
